import { applySnapshot, flow, getEnv, types } from "mobx-state-tree";

import { Login, LoginSymbol } from "./Login";
import { LoginMethod, LoginMethodType } from "./LoginMethod";
import { LoginOidcSite } from "./LoginOidcSite";
import { LoadingStatus } from "../common/LoadingStatus";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { getDI } from "../common/getDI";
import { LoginApi } from "../../services/api/LoginApi";
import { OidcProvidersResult } from "@webkintai/api";

const model = types.optional(
  types
    .model("LoginFormModel", {
      loginMethod: types.optional(LoginMethod, "LoginMethodOIDC"),

      oidcLoadingStatus: LoadingStatus,
      oidcSites: types.array(LoginOidcSite),

      disableIdPasswordLogin: types.boolean,
      userId: types.string,
      password: types.string,
      rememberLogin: types.boolean,
      inputLocked: types.optional(types.boolean, false),
    })
    .views(self => {
      const login = (): typeof Login.Type => getEnv(self).get(LoginSymbol);

      return {
        get showConsole() {
          return login().needsLogin;
        },
      };
    })
    .actions(self => {
      const loginApi = (): LoginApi => getEnv(self).get(LoginApi);
      const login = (): typeof Login.Type => getEnv(self).get(LoginSymbol);
      const appNotifier = (): AppNotifier => getDI(self, AppNotifier);

      return {
        init: flow(function*() {
          try {
            self.oidcLoadingStatus = "loading";
            const oidcProviders: OidcProvidersResult = yield loginApi().getOidcProviders();
            applySnapshot(
              self.oidcSites,
              oidcProviders.providers.map(it => ({
                id: it.id,
                name: it.name,
                url: it.loginUrl,
              })),
            );
            self.oidcLoadingStatus = "loaded";
          } catch (exception) {
            self.oidcLoadingStatus = "failed";
            appNotifier().error({ message: "連携サイトの取得に失敗しました", exception });
          }
        }),
        setLoginMethod(value: LoginMethodType) {
          self.loginMethod = value;
        },
        setUserId(value: string) {
          self.userId = value;
        },
        setPassword(value: string) {
          self.password = value;
        },
        setRememberLogin(value: boolean) {
          self.rememberLogin = value;
        },
        onSubmitLogin: flow(function*() {
          try {
            self.inputLocked = true;
            yield login().login(self.userId, self.password, self.rememberLogin);
          } finally {
            self.inputLocked = false;
          }
        }),
      };
    }),
  {
    oidcLoadingStatus: "init",
    userId: "",
    password: "",
    disableIdPasswordLogin: env.disable_id_pw_login || false,
    rememberLogin: false,
  },
);
export const LoginFormModelSymbol = "Symbol_LoginForm";
export const LoginFormModel: LoginFormModelModelType = model;
type LoginFormModelInferredType = typeof model;
export interface LoginFormModelModelType extends LoginFormModelInferredType {}
